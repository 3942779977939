import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am{" "}
            <span className="purple">Diego Suárez Ramos </span>
            from <span className="purple"> Galician, Spain.</span>
            <br />
            <br />
            Technology has always been more than just a tool for me—it’s been a
            gateway to endless possibilities. From a young age, I was drawn to
            the intricate world of computers and their components. My initial
            curiosity stemmed from a simple goal: to build a gaming PC. But what
            started as a hobby soon evolved into a deep fascination with the
            inner workings of technology. It was during this exploration that I
            discovered programming, a moment that felt like uncovering a
            treasure trove of creativity and logic. From that instant, I knew I
            had found my true calling.
            <br />
            <br />
            My journey into programming began with small, experimental projects
            in childhood, often driven by an insatiable curiosity and a desire
            to create. This passion only grew stronger over time, inspiring me
            to pursue a degree in Computer Engineering at the University of A
            Coruña, where I specialized in Computing. Though my studies covered
            a wide array of technological disciplines, software development
            stood out as my true passion. The idea of crafting solutions that
            can impact people’s lives continues to ignite my creativity and
            drive.
            <br />
            <br />
            Today, I work as a Java Developer at Denodo, where I immerse myself
            in designing and developing software solutions. For me, each project
            is not just a task—it’s an opportunity to learn, innovate, and push
            the boundaries of what’s possible. I thrive on the challenges that
            programming presents, viewing each bug or problem as a puzzle
            waiting to be solved. My goal is to grow continuously, both as a
            developer and as a creator, while enjoying every step of the
            journey.
            <br />
            <br />
            Beyond coding, I find joy in balancing my life with hobbies like
            playing video games and staying active at the gym. These activities
            fuel my creativity and keep my mind sharp, helping me approach every
            challenge with fresh energy and enthusiasm.
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
