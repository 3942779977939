import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/1708471682837.jpeg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with programming and enjoy every moment of it. My
              main focus is backend development, where I specialize in
              <b className="purple">
                {" "}
                Java and Spring Boot and more backend technologies.{" "}
              </b>
              <br />
              <br />
              In my free time, I love exploring{" "}
              <b className="purple">
                new technologies and working on personal projects.
              </b>{" "}
              Occasionally, I dive into areas outside my comfort zone,{" "}
              <b className="purple">like React and other frontend frameworks</b>
              , to expand my skill set and satisfy my curiosity.
              <br />
              <br />
              I’m always eager to <b className="purple">learn and grow</b>,
              embracing challenges that help me push the boundaries of what I
              can create.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              FIND ME <span className="purple">ON </span>
            </h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/fiopans1"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/dualwarepc"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/diego-suárez-ramos-003236162/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/diego_sr16"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
