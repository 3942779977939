import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import { DiReact, DiPython, DiGit, DiJava, DiMysql } from "react-icons/di";
import { SiDocker, SiSpring } from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.java.com";
        }}
      >
        <DiJava />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.python.org/";
        }}
      >
        <DiPython />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.docker.com";
        }}
      >
        <SiDocker />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://git-scm.com";
        }}
      >
        <DiGit />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://spring.io";
        }}
      >
        <SiSpring />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://es.react.dev";
        }}
      >
        <DiReact />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.mysql.com";
        }}
      >
        <DiMysql />
      </Col>
    </Row>
  );
}

export default Techstack;
