import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import hackaton from "../../Assets/Projects/1622214050531.png";
import practicas_carrera from "../../Assets/Projects/WhatsApp-Image-2022-11-02-at-12.10.14.jpeg";
import test_language from "../../Assets/Projects/images.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hackaton}
              isBlog={false}
              title="HackatonUDC2024"
              description="This repository hosts a Python script for code analysis using Codee, accompanied by visualization generation and HTML report creation. The script parses Codee's JSON output, creates various charts, and compiles them into an HTML report. GitHub Actions are configured for webpage hosting and email notifications."
              ghLink="https://github.com/fiopans1/HACKUDC2024-CODEE"
              demoLink="https://fiopans1.github.io/HACKUDC2024-CODEE"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hackaton}
              isBlog={false}
              title="HackatonUPC2024"
              description="This is a project from when we went to the UPC 2024 hashathon, the truth is that the final result leaves much to be desired, but we went there mostly to have fun and do activities."
              ghLink="https://github.com/fiopans1/InditexTechUPC2024MANDARINAS"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={practicas_carrera}
              isBlog={false}
              title="Career practices"
              description="PIn this repository I have all the programming practices that I did during the course of my studies."
              ghLink="https://github.com/fiopans1/practicas_carrera"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={test_language}
              isBlog={false}
              title="Test Installed Language"
              description="Possibly the dumbest project you will ever see, a bunch of files in different languages that run a main to see if that language was installed correctly."
              ghLink="https://github.com/fiopans1/test_language"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
