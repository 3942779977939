import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiIntellijidea,
  SiDocker,
  SiMacos,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href =
            "https://www.apple.com/es/macos/macos-sequoia/";
        }}
      >
        <SiMacos />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://code.visualstudio.com";
        }}
      >
        <SiVisualstudiocode />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.postman.com";
        }}
      >
        <SiPostman />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.docker.com";
        }}
      >
        <SiDocker />
      </Col>
      <Col
        xs={4}
        md={2}
        className="tech-icons"
        onClick={() => {
          window.location.href = "https://www.jetbrains.com/es-es/idea/";
        }}
      >
        <SiIntellijidea />
      </Col>
    </Row>
  );
}

export default Toolstack;
